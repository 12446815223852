html {
  background-color: #f5f6fa;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/fonts/Lato-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(./assets/fonts/Lato-Black.woff2) format('woff2');
}

body {
  margin: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  /* spacing */
  --theme-spacing-xs: 4px;
  --theme-spacing: 8px;
  --theme-spacing-sm: 12px;
  --theme-spacing-md: 16px;
  --theme-spacing-lg: 24px;
  --theme-spacing-xl: 32px;
  --theme-spacing-xxl: 36px;
  --small-padding: 8px;
  --medium-padding: 12px;
  --large-padding: 20px;

  /* custom */
  --theme-fontSize: 1rem;
  --theme-borderRadius-xs: 4px;
  --theme-borderRadius: 8px;
  --theme-borderRadius-sm: 16px;
  --theme-paginationRadius: 20px;
  --theme-mainShadow: 0px 3px 6px #00000029;

  /* colors */
  --theme-mainHover: rgb(203, 45, 62, 0.1);
  --theme-mainGreen: #11b55f;
  --theme-mainRed: #ff3e4a;
  --theme-hoverRed: #ff58641a;
  --theme-mainYellow: #fcc561;
  --theme-mainBlue: #1286ff;
  --theme-lightBlue: #a0cfff;
  --theme-mainGrey: #b0bdd0;
  --theme-mainDarkGrey: #383838;
  --theme-mainShadow: 0px 3px 6px #00000029;
  --theme-lightGrey: #f5f6fa;
  --theme-aquamarine: #5cd2c3;
  --theme-borderDisabled: #c2c2c2;
  --theme-transparent20Blue: #1287ff33;
  --theme-transparent40Blue: #1286ff66;
  --theme-transparent50Blue: #1287ff80;
  --theme-transparent60DarkGrey: #38383899;
  --theme-black: #000;
  --theme-white: #fff;

  /* gradients - !!! ADD corresponding gradient to /styles/global_gradients.svg !!! */
  --theme-gradientBlue: linear-gradient(180deg, #20d1ff 0%, #1286ff 100%);
  --theme-gradientRed: linear-gradient(180deg, #ff5964 0%, #ff3340 100%);
  --theme-gradientGreen: linear-gradient(180deg, #59cd90 0%, #11b55f 100%);
  --theme-gradientOrange: linear-gradient(180deg, #fdc661 0%, #f68661 100%);
  --theme-gradientAquamarine: linear-gradient(180deg, #5cd2c3 0%, #1298cf 100%);

  /* z-index */
  --theme-backgroundZIndex: 0;
  --theme-containerZIndex: 100;
  --theme-formZIndex: 200;
  --theme-dropdownZIndex: 300;
  --theme-popupZIndex: 400;
  --theme-tooltipZIndex: 500;
  --theme-modalZIndex: 1000;
  --theme-notificationZIndex: 1001;
}
